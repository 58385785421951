import React, { Component, useEffect, useState } from "react";
import Rule from "../inc/Rule"
import RuleForm from "../inc/RuleForm";
import "../styling/home.css"
import { connect } from "react-redux";
import { addRules } from "../../stateMgmtUsingJS/ruleActions";
import logo from "../images/yhlogo.png";


function Home(props){
    // render(){
  var base_url = process.env.REACT_APP_DASHBOARD_BE_BASE_URL
  var base_url_rule_engine = process.env.REACT_APP_RULE_ENGINE_BASE_URL

  useEffect(()=>{
    fetch(base_url+"/redis-util/getRuleListFromCache/myList")
    .then(response => {
      if (!response.ok)
        throw new Error('Network response was not ok');
      return response.json()
    })
    .then(data => {
        props.addRules(data.cacheRules)
    })
    .catch(error => {
      console.error("Error:",error)
    })

  },[])

        const handleLogout = () => props.instance.logout()

        const processRules = () => {
            fetch(base_url_rule_engine+"/apis/rule-engine/process",{
                method: "POST",
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            })
            .then(response => {
              if (!response.ok)
                throw new Error('Network response was not ok');
              return response.json()
            })
            .then(data => {
                console.log(data.recordsProcessed)
            })
            .catch(error => {
              console.error("Error:",error)
            })
        }

        console.log(props);
        const { rules } = props;
        console.log(rules)

        return (
            <div className="container">
                <div className="side-menu-div">
                    <div className="sidebar-logo">
                        <a href="#">
                            <img src={logo} alt="Logo"></img>
                        </a>
                    </div>
                    <ul className="sidebar-nav">
                        <li className="sidebar-item">
                            <a className="sidebar-link" href="#">
                                Dashboard
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a className="sidebar-link" href="#">
                                Client Support
                            </a>
                        </li>
                        <li className="sidebar-item">
                            <a href="#" className="sidebar-link collapsed has-dropdown" data-bs-toggle="collapse"
                                data-bs-target="#auth" aria-expanded="false" aria-controls="auth">
                                Admin
                            </a>
                            <ul id="auth" className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                                <li className="sidebar-item inner-list">
                                    <a href="#" className="sidebar-link">Data Processing Rules</a>
                                </li>
                                <li className="sidebar-item inner-list">
                                    <a href="#" className="sidebar-link">Anomaly Validation</a>
                                </li>
                            </ul>
                        </li>
                        <li className="sidebar-item">
                            <a className="sidebar-link" href="#" onClick={processRules}>
                                Process
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="body-div">
                        <div className="head-bar">
                            <button className="logout-btn" onClick={handleLogout}>Logout</button>
                        </div>
                        <div className="main-body">
                            <div className="add-rule-div">
                                <div className="head-tag">
                                    <h2>Admin</h2>
                                </div>
                                <RuleForm props={props}/>
                            </div>
                            
                            <div className="rule_listing-div">
                                <div className="head-tag">
                                    <h2>Data Processing Rules</h2>
                                </div>
                                {
                                    (rules.length >= 1) ? rules.map((rule,index) =>
                                    <Rule key={index} rule={rule} />) : "No Rules"
                                }
                            </div>
                        </div>            
                </div>
            </div>
        );
      }
    // }
    //all options will come from some datastore
    //will get this list from data fetched from redis
    // const rules = [
    //     {
    //         id: 1,
    //         colName: 'ent_id',
    //         condition: 'length equal to',
    //         conditionalVal: 5,
    //         type: 'M'
    //     },
    //     {
    //         id: 2,
    //         colName: 'hcpcs',
    //         condition: 'length equal to',
    //         conditionalVal: 5,
    //         type: 'M'
    //     },
    //     {
    //         id: 3,
    //         colName: 'npi',
    //         condition: 'not equal to',
    //         conditionalVal: 'null',
    //         type: 'S'
    //     }
    // ]

    // const ruleList = rules.map((rule,index) =>
    //     <Rule key={index} rule={rule} />
    // );

    

const mapStateToProps = (state) => {
    return {
        rules: state.rules
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addRules: (newRules) => dispatch(addRules(newRules))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);