import React, { Component, useEffect, useState } from "react";
import "../styling/ruleForm.css"
import { connect } from "react-redux";
import { addRules } from "../../stateMgmtUsingJS/ruleActions";
import { Store } from "react-notifications-component";

function RuleForm({props}) {
    // render() {
        var base_url = process.env.REACT_APP_DASHBOARD_BE_BASE_URL

        const ErrorLevel = {
            WARNING: 40,
            FATAL: 30
        }

        const columnList= [
            "ImportMMID",
            "ClientID",
            "ClaimNumber",
            "MemberID",
            "HCPCS",
            "HCPCS_Mod1",
            "HCPCS_Mod2",
            "HCPCS_Mod3",
            "HCPCS_Mod4",
            "HCPCS_Quantity",
            "DateofService",
            "DatePaid",
            "ProviderNPI",
            "FacilityNPI",
            "PlaceOfService",
            "StateOfAdmin",
            "DiagnosisCode",
            "ClaimStatus",
            "LineOfBusiness",
            "ReimbursedAmt",
            "AllowedAmt",
            "PatientAmt",
            "NDC",
            "Drugname",
            "UoM",
            "RxNumber",
            "PTGroupID",
            "Misc1",
            "Misc2",
            "Misc3",
            "Misc4",
            "Misc5",
            "ImportStatus",
            "ImportFileName",
            "ImportDate",
            "DateCreated",
            "UserCreated",
            "error_code",
            "failure_rules"
        ]

        console.log(base_url)
        console.log(props)

        // let column_list = props.columns[0]
        // console.log(column_list)
        // const {columns} = props;
        // console.log(columns)
        // const fields = columns[0]
        // console.log(fields)

        const [state,setState] = useState({
            field_name: '',
            condition: '',
            conditional_value: '',
            ruleList: [],
            multi_column_condition: '',
            multi_column_id: '',
            //add this dropdown in the form
            disabled: false,
            valueDisabled: "false",
            error_level: "WARNING",
            multiDisabled: "true"
        });
    

        const checkMultiDisabled = () => {
            const field_name = state.field_name;
            const condition = state.condition;
            const conditional_value = state.conditional_value;
            const multi_column_condition = state.multi_column_condition;
            const value_disabled = state.valueDisabled
            console.log(value_disabled)
            let multi_enabled = new Object();
            multi_enabled["verdict"] = false
            multi_enabled["empty_values"] = []

            if(value_disabled==="true"){
                multi_enabled["verdict"] = Boolean((field_name.length>=1)&&(condition.length>=1))
                multi_enabled["empty_values"] = [...multi_enabled["empty_values"], "Field Name" , "Condition"]
                console.log(multi_enabled)
            } else {
                multi_enabled["verdict"] = Boolean((field_name.length>=1)&&(condition.length>=1)&&(conditional_value.length>=1))
                multi_enabled["empty_values"] = [...multi_enabled["empty_values"], "Field Name" , "Condition", "Conditional Value"]
                console.log(multi_enabled)
            }

            return multi_enabled

        }

        const notification = (values) => {
           const warning_string = "Please insert values in the following dropboxes: "+values
            window.alert(warning_string)
        };
    
        const activateMultiColumn = (e) => {
            e.preventDefault();
            //add random rule id generator
            const verdict = checkMultiDisabled().verdict
            const  values = checkMultiDisabled().empty_values

            if(verdict){

                if(state.multi_column_condition.length >= 1){
                    const rule_id = Math.random().toString(36).substring(2,7)
                    const field_name = state.field_name;
                    let condition = state.condition;
                    const conditional_value = state.conditional_value;
                    const error = state.error_level

                    let error_level = ErrorLevel.WARNING
                    if(error === "FATAL"){
                        error_level = ErrorLevel.FATAL
                    } else {
                        error_level = ErrorLevel.WARNING
                    }
                    let multi_column_id = ''
                    //add multicolumn true
                    const multi_column = true
                    //random multicolumn id generator (store in state to persist for all related rules)
                    if(state.multi_column_id.length===0){
                        //generate multi id
                        multi_column_id = Math.random().toString(36).substring(2,7)
                        setState(prevState => {
                            return {
                                ...prevState,
                                multi_column_id: multi_column_id,
                                disabled: true,
                                field_name: '',
                                condition: '',
                                conditional_value: ''
                            };
                        });
                    } else {
                        multi_column_id = state.multi_column_id
                        setState(prevState => {
                            return {
                                ...prevState,
                                disabled: true,
                                field_name: '',
                                condition: '',
                                conditional_value: ''
                            };
                        });
                    }

                    const rule = {rule_id,field_name, condition, conditional_value,multi_column,multi_column_id,error_level};
                    console.log(rule)
    
                    // const newruleList= [...ruleList,rule]
            
                    setState(prevState => {
                        return {
                            ...prevState,
                            ruleList: [...state.ruleList,rule],
                            valueDisabled: "false",
                            multiDisabled: "true"
                        };
                    });
                } else {
                    notification("Multi Condition")
                }
            } else {
                notification(values)
            }
            
        };
    
        const submitProcedure = (e) => {
            e.preventDefault();
            //add random rule id generator
            const rule_id = Math.random().toString(36).substring(2,7)
            const field_name = state.field_name;
            const condition = state.condition;
            const conditional_value = state.conditional_value;
            const error = state.error_level
            let error_level = ErrorLevel.WARNING
            if(error === "FATAL"){
                error_level = ErrorLevel.FATAL
            } else {
                error_level = ErrorLevel.WARNING
            }
            let multi_column = false
            let multi_column_id = state.multi_column_id
            //put a check on ruleList length in state
            //add multicolumn true on the basis of length of rulelist length in state
            if(state.ruleList.length>0){
                multi_column = true
            } else {
                multi_column_id = 'null_'+Math.random().toString(36).substring(2,7)
            }
            //random multicolumn id generator (store in state to persist for all related rules)
            const rule = {rule_id,field_name,condition,conditional_value,multi_column,multi_column_id, error_level};
            console.log(rule);
    
            //this final rule list are the rule(s) to be added to the db and the global state
            const finalRuleList = [...state.ruleList,rule]
            console.log(finalRuleList)

            // payload for backend api

            var multiColumnObject = new Object()
            multiColumnObject.multi_column_id = multi_column_id
            multiColumnObject.multi_column_condition = state.multi_column_condition.length>=1 ? state.multi_column_condition : "AND"

            var jsonArray = {}
            jsonArray["listOfRules"] = finalRuleList
            jsonArray["multiColumnObject"] = multiColumnObject

            var jsonobject = JSON.parse(JSON.stringify(jsonArray))

            console.log(jsonobject)

    
            //here goes sending the rules to backend for persistence

            fetch(base_url+"/rule-panel/add-rule",{
                method: "POST",
                body: JSON.stringify(jsonobject),
                headers: {
                    "Content-type": "application/json; charset=UTF-8"
                }
            }).then((response)=> response.json() )
            .then((data)=> console.log(data))

            //here goes sending the rules for global state and ui display
            props.addRules(finalRuleList)
            
    
            //reset the temp states for rule addition
            setState(prevState=> {
                return {
                    ...prevState,
                    field_name: '',
                    condition: '',
                    conditional_value: '',
                    ruleList: [],
                    multi_column_condition: '',
                    multi_column_id: '',
                    disabled: false,
                    valueDisabled: "false",
                    error_level: "WARNING",
                    multiDisabled: "true"
                };
            });
        };
    
    
        useEffect (()=>{
            console.log(state.ruleList);
        },[state.ruleList])
    
        return(
            <div className="form_div">
                 <form onSubmit={submitProcedure}>
                            <div className="input-div">
                                <label>Field Name</label>
                                <select required value={state.field_name} onChange={(e) => setState(prevState => {
                                    return {...prevState,
                                        field_name: e.target.value
                                    };
                                    })}>
                                    <option></option>
                                    {
                                        // console.log(props.columns,props.columns[0])
                                        columnList.map((column)=>
                                        <option>{column}</option>
                                        ) 
                                    }
                                </select>
                            </div>
                            
                            <div className="input-div">
                            <label>Condition</label>
                            <select required value={state.condition} className={state.valueDisabled} onChange={(e) => setState(prevState => {
                               return {...prevState,
                                condition: e.target.value,
                                valueDisabled: e.target.options[e.target.selectedIndex].className
                                };
                            })}>
                                <option className="false"></option>
                                <option className="true">is NOT Alphanumeric</option>
                                <option className="true">is NOT Numeric</option>
                                <option className="true">is Null</option>
                                <option className="true">is NOT of format YYYY-MM-DD</option>
                                <option className="false">does not have value in</option>
                                <option className="false">is Equal to</option>
                                <option className="false">is NOT Equal to</option>
                                <option className="false">length is Less Than</option>
                                <option className="false">length is Less Than or Equal to</option>
                                <option className="false">length is NOT Equal to</option>
                                <option className="false">length is Greater Than or Equal to</option>
                                <option className="false">length is Greater Than</option>
                            </select>
                            </div>

                            <div className="input-div">
                            <label>Conditional Value</label>
                            <input  disabled={state.valueDisabled==="true"} type="text" value={state.conditional_value} onChange={(e) => setState(prevState => {
                               return {...prevState,
                                conditional_value: e.target.value
                                };
                            })}/>
                            </div>

                            <div className="input-div">
                            <label>Multi Condition</label>
                            <select disabled={state.disabled} value={state.multi_column_condition} onChange={(e) => setState(prevState => {
                                return {
                                    ...prevState,
                                    multi_column_condition: e.target.value
                                }
                            })}>
                                <option></option>
                                <option>AND</option>
                                <option>OR</option>
                            </select>

                            </div>

                            <div className="input-div">
                            <label>Error Level</label>
                            <select disabled={state.disabled} value={state.error_level} onChange={(e) => setState(prevState => {
                                return {
                                    ...prevState,
                                    error_level: e.target.value
                                }
                            })}>
                                <option>WARNING</option>
                                <option>FATAL</option>
                            </select>

                            </div>
    
                            <button  className="multi_btn" onClick={activateMultiColumn}>Multi?</button>
                            {/* <input type="checkbox" checked={isChecked} onChange={()=> setIsChecked(!isChecked)}/>
                            {
                                isChecked && 
                                <div>
                                  <label>MultiColumn Condition : OR</label>
                                </div>
                            } */}
                            <button className="submit_btn" type="submit">Submit</button>
                        </form>
            </div>
           
        );

    }
   
// }

const mapStateToProps = (state) => {
    return {
        rules: state.rules
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addRules: (newRules) => dispatch(addRules(newRules))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(RuleForm);
// export default RuleForm