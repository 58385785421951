export const addRules = (newRules) => {
    return {
        type: 'ADD_RULES',
        newRuleList: newRules
    }
}

export const delRules = (id) => {
    return {
        type: 'DEL_RULES',
        inputId: id
    }
}