const initState = {
    rules: []
}

// axios.get('http://127.0.0.1:7777/redis-util/getRuleListFromCache/myList')
//                 .then(data => {
//                     console.log(data.data)
//                     initState.rules.concat(data.data)
//                 });

const ruleReducer = (state = initState, action)=>{ //action will have type and  newrule list
    switch(action.type){
        case 'ADD_RULES': return {
            ...state,
            rules: state.rules.concat(action.newRuleList)
        }

        case 'DEL_RULES': return {
            ...state,
            rules: state.rules.filter(rule => rule.multi_column_id !== action.inputId)
        }

        default: return state
        
    }
}

export default ruleReducer;

