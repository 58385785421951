import React from "react";
import "../styling/rule.css";
import { connect } from "react-redux";
import { delRules } from "../../stateMgmtUsingJS/ruleActions";

function Rule(props) { //parameter passed for unpackaging rule info

    var base_url = process.env.REACT_APP_DASHBOARD_BE_BASE_URL
    console.log(base_url)

    const submitDelete = (e) =>{
        const idToBeDeleted = e.id;

        var jsonArray = {}
        jsonArray["ruleToBeDeleted"] = props.rule

        console.log(jsonArray)

        fetch(base_url+"/rule-panel/del-rule",{
            method: "POST",
            body: JSON.stringify(jsonArray),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        }).then((response)=> response.json())
          .then((data)=> console.log(data))
        
        props.delRules(props.rule.multi_column_id)
        
    }

    console.log(props)

    return(
        <div className="container">
            <p>{props.rule.field_name}</p>
            <p>{props.rule.condition}</p>
            <p>{props.rule.conditional_value}</p>
            {
                props.rule.multi_column?<p>M</p>:<p>S</p>
            }
            <button className="delete_btn" id={props.rule.rule_id} onClick={submitDelete}>Delete</button>
            {/* <p>{rule.active}</p> */}
        </div>
    );

}

const mapStateToProps = (state) => {
    return {
        rules: state.rules
    }
}

const mapDispatchToProps = dispatch => {
    return {
        delRules: (id) => dispatch(delRules(id))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(Rule)