import './App.css';
import Home from './components/pages/Home';
import Login from './components/pages/Login';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal, MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux';
import store  from './stateMgmt/store';
import { ReactNotifications } from 'react-notifications-component';
import "react-notifications-component/dist/theme.css";
import { useEffect, useState } from 'react';
import { json } from 'react-router-dom';

const WrappedView = ({columns}) => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();

  console.log(columns)

  return (
    <Provider store={store}>
    <div className='App'>
        <AuthenticatedTemplate>
          { activeAccount ? 
          <Home columns={columns} account={activeAccount} instance = {instance} />
            : null}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Login instance={instance}/>
        </UnauthenticatedTemplate>
    </div>
    </Provider>
  );

};


function App({instance}) {

  let columns = []

  // const [data,setData] = useState()
  // var base_url = process.env.REACT_APP_DASHBOARD_BE_BASE_URL

  // useEffect(()=>{
  //   fetch(base_url+"/redis-util/getRuleListFromCache/myList")
  //   .then(response => {
  //     if (!response.ok)
  //       throw new Error('Network response was not ok');
  //     return response.json()
  //   })
  //   .then(data => {
  //     setData(Object.values(data.cacheRules))
  //   })
  //   .catch(error => {
  //     console.error("Error:",error)
  //   })
  //   // .then(jsonData => {
  //   // console.log(jsonData.cacheRules)

  //   // setData(Object.values(jsonData.cacheRules))
    
  //   // let initRuleList = []

  //   // for(let i=0;i<Object.values(jsonData.cacheRules).length;i++){
  //   //   initRuleList.concat(Object.values(jsonData.cacheRules)[i])
  //   // }

  //   // console.log(initRuleList)
  //   // console.log(typeof initRuleList)


  //   // });
  // },[])

  
  return (
    
    <MsalProvider instance={instance}>
        <WrappedView columns={columns} />
    </MsalProvider>
    
  );
};

export default App;
