import React from "react";
import { loginRequest } from "../../auth-config";
import "../styling/login.css"

function Login ({instance}) {

    const redirect = () => {
        instance.
                loginRedirect({
                  ...loginRequest,
                  prompt: 'create',
                })
                .catch((error) => console.log(error));
      };

    return (
        <body>
            <div id="container">
                <div id="left_div">
                    <div id="outer_circle_des">
                        <div id="inner_circle_des">
                        </div>
                    </div>
                </div>
                <div id="right_div">
                    <button id="enter_btn" onClick={redirect}>
                        Enter
                    </button>
                </div>
            </div>
        </body>
        
    );
}

export default Login;