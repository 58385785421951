import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {

    auth: {
        clientId:'352ddb0b-488d-476e-bd8a-5f80df70487d',
        authority: 'https://login.microsoftonline.com/yupanahealth.com',
        redirectUrl: '/',
        postLogoutRedirectUrl: '/',
        navigateToLoginRequestUrl: false,
    },
    cache: {
        caceLocation: 'sessionStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if(containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },

};

export const loginRequest = {
    scopes:['user.read'],
};